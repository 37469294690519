import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Container from '../UI/Container'
import styled from 'styled-components'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const Wrapper = styled.nav`
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  background: ${props => props.theme.colors.grey};
  border-bottom: 5px solid ${props => props.theme.colors.red};
  position: ${props => (props.sub ? 'absolute' : 'relative')};
  z-index: ${props => (props.sub ? '5' : '10')};
  top: ${props => (props.sub && props.open ? '60px' : '0')};
  transition: top 0.3s;

  .header {
    display: flex;
    color: white;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

    &.subheader {
    }

    .logo {
      display: flex;
    }

    .nav {
      height: 100%;
      display: flex;
      align-items: center;
      width: 50%;
      &:last-child {
        justify-content: flex-end;
      }

      &.subnav {
        justify-content: flex-start !important;
      }

      a,
      button {
        font-family: ${props => props.theme.fonts.head};
        font-size: 18px;
        margin: 0;
        text-decoration: none;
        color: white;
        padding: 0 0.75rem;
        height: 100%;
        display: flex;
        align-items: center;
        background: transparent;
        border: none;
        cursor: pointer;

        &:focus {
          outline: none;
        }

        &[aria-current='page'] {
          background: ${props => props.theme.colors.red};
          color: white;
        }
      }
    }
  }
`

export default class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      menuOpen: false,
    }
  }

  static propTypes = {
    location: PropTypes.object,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({ menuOpen: false })
    }
  }

  handleToggle = e => {
    e.preventDefault()
    this.setState({ menuOpen: !this.state.menuOpen })
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            file(relativePath: { eq: "logo-white.png" }) {
              childImageSharp {
                fixed(width: 76, height: 39) {
                  ...GatsbyImageSharpFixed_tracedSVG
                }
              }
            }
          }
        `}
        render={data => (
          <Fragment>
            <Wrapper>
              <Container>
                <div className="header">
                  <div className="nav">
                    <Link to="/">Home</Link>
                    <button
                      onClick={e => this.handleToggle(e)}
                      className={this.state.menuOpen ? 'active' : null}
                    >
                      Fleet
                    </button>
                  </div>
                  <div className="logo">
                    <Img fixed={data.file.childImageSharp.fixed} />
                  </div>
                  <div className="nav">
                    <Link to="/team">Team</Link>
                    <Link to="/contact">Contact</Link>
                  </div>
                </div>
              </Container>
            </Wrapper>
            <Wrapper sub open={this.state.menuOpen}>
              <Container>
                <div className="header subheader">
                  <div className="nav subnav">
                    <Link to="/fleet/land">Land</Link>
                    <Link to="/fleet/air">Air</Link>
                    <Link to="/fleet/sea">Sea</Link>
                    <Link to="/fleet">All</Link>
                  </div>
                </div>
              </Container>
            </Wrapper>
          </Fragment>
        )}
      />
    )
  }
}
