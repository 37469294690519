// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-fleet-category-template-js": () => import("./../src/templates/FleetCategoryTemplate.js" /* webpackChunkName: "component---src-templates-fleet-category-template-js" */),
  "component---src-templates-fleet-vehicle-template-js": () => import("./../src/templates/FleetVehicleTemplate.js" /* webpackChunkName: "component---src-templates-fleet-vehicle-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fleet-js": () => import("./../src/pages/fleet.js" /* webpackChunkName: "component---src-pages-fleet-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-js": () => import("./../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */)
}

