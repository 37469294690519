import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Header from './Nav/Header'
import Footer from './Nav/Footer'
import Reboot from '../styles/reboot'
import theme from '../styles/theme'
import GlobalStyle from '../styles/global'
import { ThemeProvider } from 'styled-components'
import styled from 'styled-components'

const SiteRoot = styled.div`
  background: #e3e3e3;
`

const Layout = ({ children, location }) => (
  <SiteRoot>
    <Helmet>
      <link rel="stylesheet" href="https://use.typekit.net/psy5dew.css" />
      <html lang="en" />
    </Helmet>
    <ThemeProvider theme={theme}>
      <Fragment>
        <Header location={location} />
        {children}
        <Footer />
      </Fragment>
    </ThemeProvider>
    <GlobalStyle />
    <Reboot />
  </SiteRoot>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
