const theme = {
  colors: {
    grey: '#232324',
    greyDark: '#19191A',
    greyLight: '#E3E3E3',
    red: '#CD021B',
  },
  fonts: {
    head: `"bebas-neue-by-fontfabric",sans-serif`,
    body: `"effra",sans-serif`,
  },
  sizes: {
    mobile: '600px',
    tablet: '900px',
  },
}

export default theme
