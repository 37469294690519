import React, { Component } from 'react'
import Container from '../UI/Container'
import styled from 'styled-components'
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  .top {
    background: ${props => props.theme.colors.grey};
    padding: 1.5rem 0;
    section {
      display: flex;
      justify-content: flex-end;
      .nav {
        a {
          font-family: ${props => props.theme.fonts.head};
          font-size: 18px;
          text-decoration: none;
          color: white;
          margin-right: 1rem;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  .bottom {
    background: ${props => props.theme.colors.greyDark};
    padding: 0.5rem 0;
    section {
      justify-content: space-between;
      align-items: center;
      p {
        margin: 0;
        font-size: 15px;
      }
      .logo {
        display: flex;
      }
    }
  }
`

export default class Footer extends Component {
  _renderYear = () => {
    return new Date().getFullYear()
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            file(relativePath: { eq: "logo-white.png" }) {
              childImageSharp {
                fixed(width: 45, height: 23) {
                  ...GatsbyImageSharpFixed_tracedSVG
                }
              }
            }
          }
        `}
        render={data => (
          <Wrapper>
            <div className="top">
              <Container>
                <div className="nav">
                  <Link to="/">Home</Link>
                  <Link to="/fleet">Fleet</Link>
                  <Link to="/team">Team</Link>
                  <Link to="/contact">Contact</Link>
                </div>
              </Container>
            </div>
            <div className="bottom">
              <Container>
                <div className="copyright">
                  <p>Copyright &copy; {this._renderYear()} ― Segel Group</p>
                </div>
                <div className="logo">
                  <Img fixed={data.file.childImageSharp.fixed} />
                </div>
              </Container>
            </div>
          </Wrapper>
        )}
      />
    )
  }
}
