import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  p, a, li, span, small, form {
    font-family: "effra",sans-serif;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: "bebas-neue-by-fontfabric",sans-serif;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`
export default GlobalStyle
